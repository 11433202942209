import axios from 'axios';

const UploadApi = axios.create({
  timeout: 300000,
  baseURL:
    //"http://localhost:8001/",
    'https://server.approvedshopifyapp.com/',
  headers: {
    'Content-Type': 'application/json',
  }
});

export default UploadApi;