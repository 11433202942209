export function getBadgeByStatus(status, flag) {
  const badges = {
    "Design Approval Needed": (
      <div className="badge design-approval-needed">
        {flag && <div className="icon"></div>}
        Design Approval Needed
      </div>
    ),
    "Event List Needed": (
      <div className="badge event-list-needed">
        {flag && <div className="icon"></div>}
        Event List Needed
      </div>
    ),
    "Proof Revision Needed": (
      <div className="badge proof-revision-needed">
        {flag && <div className="icon"></div>}
        Proof Revision Needed
      </div>
    ),
    "Drawing Needed": (
      <div className="badge awaiting-design">
        {flag && <div className="icon"></div>}
        Drawing Needed
      </div>
    ),
    "Pending Approval": (
      <div className="badge pending-approval">
        {flag && <div className="icon"></div>}
        Pending Approval
      </div>
    ),
    "Awaiting Shipment": (
      <div className="badge awaiting-shipment">
        {flag && <div className="icon"></div>}
        Awaiting Shipment
      </div>
    ),
    "Awaiting Proof": (
      <div className="badge awaiting-proof">
        {flag && <div className="icon"></div>}
        Awaiting Proof
      </div>
    ),
    "Proof Needed": (
      <div className="badge proof-needed">
        {flag && <div className="icon"></div>}
        Proof Needed
      </div>
    ),
    "Proof Added": (
      <div className="badge proof-added">
        {flag && <div className="icon"></div>}
        Proof Added
      </div>
    ),
    "Proof Sent": (
      <div className="badge proof-sent">
        {flag && <div className="icon"></div>}
        Proof Sent
      </div>
    ),
    "Proof Rejected": (
      <div className="badge proof-rejected">
        {flag && <div className="icon"></div>}
        Proof Rejected
      </div>
    ),
    "Rush": (
      <div className="badge proof-rejected">
        {flag && <div className="icon"></div>}
        Rush
      </div>
    ),
    "Remake": (
      <div className="badge remake">
        {flag && <div className="icon"></div>}
        Remake
      </div>
    ),
    "Proof Accepted": (
      <div className="badge proof-accepted">
        {flag && <div className="icon"></div>}
        Proof Accepted
      </div>
    ),
    "Fulfilled": (
      <div className="badge order-fulfilled">
        {flag && <div className="icon"></div>}
        Fulfilled
      </div>
    ),
    "Backorder": (
      <div className="badge backorder">
        {flag && <div className="icon"></div>}
        Backorder
      </div>
    ),
    "In production": (
      <div className="badge production">
        {flag && <div className="icon"></div>}
        In Production
      </div>
    ),
    "paid": (
      <div className="badge paid">
        {flag && <div className="icon"></div>}
        Paid
      </div>
    ),
    "pending": (
      <div className="badge payment-pending">
        {flag && <div className="icon"></div>}
        Payment Pending
      </div>
    ),
    "partially_paid": (
      <div className="badge payment-pending">
        {flag && <div className="icon"></div>}
        Partially Paid
      </div>
    ),
    "refunded": (
      <div className="badge payment-pending">
        {flag && <div className="icon"></div>}
        Refunded
      </div>
    ),
    "voided": (
      <div className="badge payment-pending">
        {flag && <div className="icon"></div>}
        Voided
      </div>
    ),
    "partially_refunded": (
      <div className="badge payment-pending">
        {flag && <div className="icon"></div>}
        Partially Refunded
      </div>
    ),
    "unpaid": (
      <div className="badge payment-pending">
        {flag && <div className="icon"></div>}
        Unpaid
      </div>
    ),
    "default": (
      <div className="badge default pointer">
        {flag && <div className="icon"></div>}
        {status||"Default Badge"}
      </div>
    ),
    "Normal": (
      <div className="badge production">
        {flag && <div className="icon"></div>}
        Normal
      </div>
    ),
  };

  return badges[status] || badges["default"];
}

export function timeAgo(timestamp) {
  const now = new Date();
  const past = new Date(timestamp);
  const secondsPast = Math.floor((now - past) / 1000);

  if (secondsPast < 60) {
      return `${secondsPast} second${secondsPast === 1 ? '' : 's'}`;
  }

  const minutesPast = Math.floor(secondsPast / 60);
  if (minutesPast < 60) {
      return `${minutesPast} minute${minutesPast === 1 ? '' : 's'}`;
  }

  const hoursPast = Math.floor(minutesPast / 60);
  if (hoursPast < 24) {
      return `${hoursPast} hour${hoursPast === 1 ? '' : 's'}`;
  }

  const daysPast = Math.floor(hoursPast / 24);
  if (daysPast < 7) {
      return `${daysPast} day${daysPast === 1 ? '' : 's'}`;
  }

  const weeksPast = Math.floor(daysPast / 7);
  if (weeksPast > 0) {
      return `${weeksPast} week${weeksPast === 1 ? '' : 's'}`;
  }
}