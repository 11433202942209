import {
  Box,
  Text,
  Icon,
  Modal,
  TextField,
  Badge,
  Button,
  DropZone,
  Grid,
  Collapsible,
  LegacyCard,
  DataTable,
  Select,
  ProgressBar,
} from "@shopify/polaris";
import { useState, useCallback, useEffect } from 'react';
import "./styles.customorder.css";
import { ViewIcon, UploadIcon,StatusActiveIcon,AlertDiamondIcon, DeleteIcon, AttachmentFilledIcon, XCircleIcon} from "@shopify/polaris-icons";
import "./styles.customorder.css";
import api from "../axiosClient";
import ModalExample from "./pdf";
import UploadApi from "../axiosClientUpload";

function formatDate(dateString) {
  const options = {
    day: '2-digit',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };
  
  const date = new Date(dateString);
  return date.toLocaleString('en-US', options);
}


export default function OrderProofFile({ id, shop, files, setOrderList, user, setLoading}) {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(true);
  const [comment, setComment] = useState("");
  const [proofFiles, setProofFiles] = useState([]);
  const [file, setFile] = useState({});
  const [numPages, setNumPages] = useState(0);
  const [progress, setProgress] = useState(0);
  const [apiCallComplete, setApiCallComplete] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [activeMsg, setActiveMsg] = useState({active:false, title: "", msg: ""});
  

  
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleActiveMsg = useCallback((title = "", msg = "") => {
    setActiveMsg(prevActiveMsg => ({
        active: !prevActiveMsg.active,
        title: title,
        msg: msg
    }));
}, []);
  

  useEffect(() => { 
  }, []);

  useEffect(() => { 
    if (files?.length > 0) {
      setProofFiles(files.filter((f) => f.isProofAdded))
    }else setProofFiles([])
  }, [files]);

  const handleViewFile = (file) => {
    setFile(file);
    setActive(true);
  }

 ;

  

  const handleDropZoneDrop = 
   (_dropFiles, acceptedFiles, _rejectedFiles) => {
      if(acceptedFiles.length) {
        setProgress(0);
        setDisabled(true);
        setLoading(true);
        let file = acceptedFiles[0];

        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = async () => {

          // Make a fileInfo Object
          let fileInfo = {
            name: file.name,
            type: file.type,
            size: Math.round(file.size / 1000) + ' kB',
            base64: reader.result,
            file: file,
            user: user.artist.name,
            email: user.artist.email
          };
          const data = {
            id, shop,
            file: fileInfo 
          }
          console.log(data)

          const interval = setInterval(() => {
            setProgress(prev => {
              if (prev < 80) {
                return prev + 5; // 80% over 30 seconds, update every second
              }
              clearInterval(interval);
              return prev;
            });
          }, 1000);
          
          UploadApi.post("/orderDetailsUploadNewProof", data).then((result) => {
            if(result.data.id)
              setOrderList(result.data);
              setProgress(100);
              setApiCallComplete(true);
            setDisabled(false);
            setLoading(false);
            
          }).catch((err) => {
            setProgress(100);
            setApiCallComplete(true);
            setDisabled(false);
            
            setLoading(false);
          });
          
        }
      }
    };
  
  
    useEffect(() => {
      if (apiCallComplete) {
        setProgress(100);
      }
    }, [apiCallComplete]);
  
    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
    }
  
  const handleDeleteFile = (name) => {
    setLoading(true);
    const data = {
      id, shop,
      name: name 
    }
    api.post("/orderDetailsDeleteProofFile", data).then((result) => {
      if(result.data.id)
        setOrderList(result.data);
      setLoading(false);
    });
    toggleActive();
  }

  const handleApproveFile = (name) => {
    setLoading(true);
    const data = {
      id, shop,
      name: name?name:file.name,
      file: {
        "files.$.isProofAccepted": true,
        "files.$.isProofRejected": false,
        "files.$.isProofSent": true,
        "files.$.acceptedDate": new Date().toISOString(),
        "files.$.sentDate": new Date().toISOString(),
        "files.$.comment": comment,
      },
      user: user.artist.name,
      email: user.artist.email
    }
    api.post("/orderDetailsFileUpdate", data).then((result) => {
      if (result.data.id) {
        setOrderList(result.data);
        
      }
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      
    });
    setActive(false);
  }

  const handleRejectFile = (name) => {
    setLoading(true);
    const data = {
      id, shop,
      name: file.name,
      file: {
        "files.$.isProofRejected": true,
        "files.$.isProofAccepted": false,
        "files.$.rejectedDate": new Date().toISOString(),
        "files.$.comment": comment,
      },
      user: user.artist.name,
      email: user.artist.email
    }
    api.post("/orderDetailsFileUpdate", data).then((result) => {
      setLoading(false);
      if (result.data.id) {
      setOrderList(result.data);      
    }
    }).catch(error => {
      setLoading(false);
    });
  }

  const transformSpellCheck = (file) => {
    return file.spellCheck?.flatMap((item) => 
      item.corrections?.filter((corr) => corr.text.length > 2).map((corr) => [
        corr.text,
        corr.best_candidate,
        <Select options={corr.candidates} />
      ])
    ) || [];
  };

  const getFileExtension = (link, type) => {
    // Extract the expected extension from the MIME type
    const expectedExtension = '.' + type?.split('/')[1];

    // If the URL already contains a file extension at the end, return an empty string
    if (/\.[a-zA-Z0-9]+$/.test(link)) {
        return '';
    }

    // Otherwise, append the correct extension
    return expectedExtension;
};

  
  
  return (<>
    <Box id="proof-file-box">  
      <div className="column-header">
            <Text as="h6" tone="subdued" fontWeight="semibold" >
          Proof File <Badge>{ proofFiles.length}</Badge>
              </Text>  
             
              
            </div> 
            <Collapsible
      open={open}
      id="basic-collapsible"
      transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
      expandOnPrint
        >
        <div className="right-column-body">
        <DropZone disabled={disabled}   onDrop={handleDropZoneDrop} variableHeight>
            <div style={{width:"100%"}}><div style={{padding:"4px 20px"}} className="btn">Add Proof files</div></div>
            {progress > 0 && <span style={{width: 225}}>
              <ProgressBar progress={progress} size="small" />
            </span>}
              </DropZone>
              <div style={{marginTop:"10px"}}>
          <Grid columns={{xs:3 , sm: 2}}>
              {proofFiles.map((data) => <>
            <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 6, xl: 6}}>
                <div style={{ width: "100%", aspectRatio: "16/6", backgroundColor: "#eb2310", borderRadius: "5px",backgroundSize:"cover", backgroundImage: data.type.includes('image')?`url('${data.link}')`:"none" }}>
              <div style={{height:"85%", display:"flex", justifyContent:"space-between", padding: "5px"}}>
                  <span style={{color:"white", backgroundColor:"rgb(127, 23, 11)", height:"fit-content", padding:"2px 5px", borderRadius:"3px", fontSize:"10px", textTransform:"uppercase"}}>{data.type.substring(data.type.lastIndexOf("/") + 1)}</span>
                      <span style={{ height: "fit-content", display: "flex", color: "#FFFFFF" }}><a target="_blank" 
       rel="noopener noreferrer"  style={{ transform: "scaleY(-1)", height: "fit-content", color: "#FFFFFF" }} href={`${data.link}${getFileExtension(data.link, data.type)}`}> <span ><Icon tone="inherit" source={UploadIcon} /></span></a> <span onClick={() => handleDeleteFile(data.name)}> <Icon tone="inherit" source={DeleteIcon} /></span></span>
              </div>
              <div style={{padding:"15px 5px 5px", overflow: "hidden", textOverflow: "ellipsis", color: "#ffffff", backgroundSize:"cover", backgroundImage: data.type.includes('image')?`none`:"linear-gradient(to bottom, #eb2310, #470b01)", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", fontSize:"10px"}}>
                  <span style={{whiteSpace: "nowrap", maxWidth:"100%", 
                    overflow: "hidden",textOverflow: "ellipsis"}}>{data.name}</span>    
                  <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}><p>{data.size}</p><Badge tone={  data.isProofAccepted ? "success" : data.isProofRejected ? "critical" : "attention" }>{  data.isProofAccepted ? "accepted" : data.isProofRejected ? "rejected" : "new" }</Badge></div>
              </div>
              </div>    
              </Grid.Cell>
                    <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                      <div style={{
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
                    height: "100%",
                    minHeight: "60px",
                    alignItems: "flex-start"
}}>
                    <Text as="p">{data.user ? `${data.user} uploaded this on` :`Uploaded date`} {formatDate(data.proofUploadUploadDate)} </Text>
                    <div onClick={() => handleViewFile(data)}  style={{padding:"4px 10px", minWidth:"60px"}} className="btn">View</div>
                    </div>
                    
              </Grid.Cell>
              </>
              )}
              </Grid>
              </div>
        </div>  
        </Collapsible>
          </Box>
        <Modal
          size="fullScreen"
          open={active}
          onClose={toggleActive}
          title={file.name}
        >
      <Modal.Section>
      {file?.type?.includes('image') ? <img src={file.link} width={"100%"} /> :
          <ModalExample fileUrl={`${file.link}${getFileExtension(file.link, file.type)}`}  />}
        {!(file.isProofAccepted || file.isProofRejected) && <TextField
          placeholder="Add a comment..."
          value={comment}
          onChange={setComment}
          multiline={3}
          autoComplete="off"
        />}
        
        {file.text && <div style={{display:"inline-grid", gap:"8px", marginTop:"10px"}}>
          <Text as="p" variant="headingMd" tone="subdued">PDF text content</Text>
          <Text variant="bodyMd" tone="subdued">{file.text}</Text>
          <Text as="p" variant="headingMd" tone="subdued">Spell Check and Suggestions</Text>
          <div>
            <LegacyCard>
        <DataTable
          columnContentTypes={[
            'text',
            'text',
            'text',
          ]}
          headings={[
            'Word',
            'Best Match',
            'Suggestions',
          ]}
                rows={transformSpellCheck(file)}
        />
      </LegacyCard>

          </div>
        </div>
        }
      </Modal.Section>
      {(!(file.isProofAccepted || file.isProofRejected) && user?.artist?.permissions?.admin) && <Modal.Section>
      <div style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        background: "#fff",
        padding: "10px 20px",
        boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
        display: "flex",
        justifyContent: "flex-end",
        gap: "15px",
        zIndex: 10,
      }}>
          <div onClick={() => handleRejectFile()} style={{ padding: "4px 10px", minWidth: "60px" }} className="btn reject-btn">Reject Proof</div>
          <div onClick={() => handleApproveFile()} style={{ padding: "4px 10px", minWidth: "60px" }} className="btn approved-btn">Approve Proof</div>
      </div>
      </Modal.Section>}
      </Modal>
      <Modal
      size="small"
      open={activeMsg.active}
      onClose={toggleActiveMsg}
      title={activeMsg.type === 'success' ? "Success" : "Error"}
    >
      <Modal.Section>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {activeMsg.type === 'success' ? (
            <>
              <span style={{width:"40px"}}><Icon source={StatusActiveIcon} tone="success" /></span>
              <span >{activeMsg.msg}</span>
            </>
          ) : (
            <>
              <span style={{width:"40px"}}><Icon source={AlertDiamondIcon}  tone="critical"/></span>
              <span style={{ marginLeft: 8 }}>{activeMsg.msg}</span>
            </>
          )}
        </div>
      </Modal.Section>
      <Modal.Section>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: "15px" }}>
          <div onClick={() => toggleActiveMsg()} style={{ padding: "4px 10px", minWidth: "60px" }} className="btn">Close</div>
      </div>
      </Modal.Section>
    </Modal>
        
    </>
  );
}
            
