import {
  Box,
  Text,
  Icon,
  Button,
} from "@shopify/polaris";
import "./styles.customorder.css";
import { DisabledIcon} from "@shopify/polaris-icons";
import "./styles.customorder.css";
import { useEffect, useState } from "react";
import api from "../axiosClient";


export default function OrderProofRejectedBanner({ transactions, shop, id, order, setOrderList, user, setLoading }) {

  const [proofFile, setProofFile] = useState([]);

  useEffect(() => { 
    if (order?.files?.length > 0) {
      setProofFile(order.files.filter((f) => f.isProofSent))
    }else setProofFile([])
  }, [order]);
  
  const formatter = new Intl.DateTimeFormat('en', {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  const handleApproveFile = () => {
    setLoading(true);
    const data = {
      id: order.id,
      shop: order.shop,
      name: proofFile[proofFile.length - 1].name,
      link: proofFile[proofFile.length-1].link,
      file: {
        "files.$.customerAccepted": true,
        "files.$.customerAcceptedManually": true,
        "files.$.customerRejected": false,
        "files.$.customerAcceptedDate": new Date().toISOString(),
      },
      user: user.artist.name,
      email: user.artist.email
    }
    api.post("/orderDetailsFileUpdate", data).then((result) => {
      if(result.data.id)
        setOrderList(result.data);
        setLoading(false);
    }).catch((err) => setLoading(false));
  }

  return (
    <Box width="100%">
    <div className="banner banner-rejected">
        <span className="icon-rejected-wrapper"><Icon size="small" source={DisabledIcon}/></span>
        <div className="banner-body">
          <Text  variant="headingLg" as="h3" tone="subdued" fontWeight="bold">Proof rejected</Text>
          <div className="banner-body-list">
          {order.current_status_by === 0 ? <Text tone="subdued"  variant="headingMd" as="p" fontWeight="regular" >{`You manually reject this order ${order?.current_status_date && " on " + formatter.format(new Date(order?.current_status_date))}`}</Text> :
              <Text tone="subdued"  variant="headingMd" as="p" fontWeight="regular" > {`${order.customer.last_name && order.customer.first_name ? `${order.customer.first_name} ${order.customer.last_name}` : order.customer.first_name ? `${order.customer.first_name}` : order.customer.last_name ? `${order.customer.last_name}` : ""} has rejected your proof ${order?.current_status_date && "on" + formatter.format(new Date(order?.current_status_date))}`}</Text>}
          </div>
            <div className="banner-action">
            <div onClick={handleApproveFile} className="btn approved-btn"> Approve Manually</div>
        </div>
        </div>
        
    </div>  
    </Box>         
  );
}
            
