import {
  Card,
  Page,
  Text,
  Button,
  Select,
  TextField,
  IndexTable,
  useBreakpoints,
  Icon,
  Popover,
  LegacyCard,
  BlockStack,
  Box,
  DatePicker,
  ActionList,
  Modal,
  Grid,
  Checkbox,
  Tabs,
} from "@shopify/polaris";
import {Badge, LegacyTabs} from '@shopify/polaris';
import { useState, useCallback, useEffect, useRef } from 'react';
import "./styles.customorder.css";
import { OrderIcon,CalendarIcon,PersonIcon,OrderDraftIcon,OrdersStatusIcon,StatusIcon,PaymentIcon,CircleChevronUpIcon,ClockIcon,LabelPrinterIcon,CalendarTimeIcon,LayoutColumns2Icon,ArrowLeftIcon, ArrowRightIcon,LayoutLogoBlockIcon, SearchIcon, XIcon } from "@shopify/polaris-icons";
import api from "../axiosClient";
import ReactSwitch from "react-switch";
import { useNavigate } from "react-router-dom";
import OrderHistoryTableComp from "./HIstoryTable";
import CustomSpinner from "./Loader";
import ReactToPrint from 'react-to-print';
import { getBadgeByStatus, timeAgo } from "../utlis";

const tags = {
  AWAITING_PROOF: { title: "Awaiting Proof", tone: "attention" },
  AWAITING_DESIGN: {title:"Drawing Needed", tone: "critical"},
  SENT: {title:"Proof Sent", tone: "attention"},
  ACCEPTED: {title:"Proof Accepted", tone: "success"},
  REJECTED: {title:"Proof Rejected", tone: "critical"},
  PROOF_ADDED: {title:"Proof Added", tone: "info"},
  HOT_LIST: { title: "Hot List", tone: "critical-strong" },
  ATTENTION: { title: "Awaiting Attention", tone: "critical-strong" },
  FULFILLED: {
    title: "Fulfilled",
    tone: "info"
  },
}

export default function CustomOrderList({shop}) {
  const [selected, setSelected] = useState(0);
  const [popoverActive, setPopoverActive] = useState(false);
  const [tagValue, setTagValue] = useState('');
  const [orderList, setOrderList] = useState([]);
  const navigate = useNavigate();
  const [activePopover, setActivePopover] = useState(null);
  const [activePopoverPriority, setActivePopoverPriority] = useState(null);
  const [activePopoverCurrentStatus, setActivePopoverCurrentStatus] = useState(null);
  const [activeBadge, setActiveBadge] = useState({});
  const [active, setActive] = useState(false);
  const [activeHistory, setActiveHistory] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedComment, setSelectedComment] = useState('');
  const [selectedCheckbox, setSelectedCheckbox] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({});

  const [activeColumns, setActiveColumns] = useState({});
  //   {
  //   orderId: true,
  //   orderDate: true,
  //   dueDate: true,
  //   customerName: true,
  //   orderStatus: true,
  //   taskStatus: true,
  //   paidStatus: true,
  //   Priority: true,
  //   poDate: true,
  //   history: false,
  //   currentStatus: true,
  //   poStatus: true,
  //   editCurrentStatus: true
  // });

  const handleGetSettings = () => {
    api.get(`/getSettingsByStore?shop=${shop}`).then((data) => {
      setSettings(data.data);
      if (data.data.activeColumns) {
        setActiveColumns({...data.data.activeColumns, paidStatus: user?.artist?.permissions?.admin ? data.data.activeColumns.paidStatus: false});
      }
    });
  }
  console.log("active ", activeColumns)
  const handleUpdateSetting = (data) => {
    api.put("/updatSettingsByStore",data).then((result) => {
      setSettings(result.data);   
      if (result.data.activeColumns) {
        setActiveColumns(result.data.activeColumns);
      }
    });
  }

  const handleSave = (activeColumns) => {
    const data = {
      shop: settings.shop,
      setting: {
        activeColumns: activeColumns
      }
    }
    handleUpdateSetting(data);
  }

  useEffect(() => {
    if (settings.activeColumns) {
      setActiveColumns({...settings.activeColumns, paidStatus: user?.artist?.permissions?.admin ? settings.activeColumns.paidStatus: false});
    }
  }, [settings]);


  useEffect(() => {
    try {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser && storedUser?.artist) {
        setUser(storedUser);
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }
  }, []);
  const toggleActive = () => {
    setActive((active) => !active)
    setSelectedCheckbox(false);
    setSelectedComment('');
    setSelectedStatus('');
    handleActiveRowToggle(null);
  };

  const toggleActiveHistory = () => {
    setActiveHistory((activeHistory) => !activeHistory)
    handleActiveRowToggle(null);
  };

  const handlePopoverToggle = (rowIndex) => {
    setActivePopover(activePopover === rowIndex ? null : rowIndex);
  };

  const handleActiveRowToggle = (rowIndex=null) => {
    setActiveRow(rowIndex ? rowIndex : null);
  };

  const handlePrintPurshaseOrder = async (PO_link, id) => {
    setLoading(true);
    const data = {
      id: id,
      user: user.artist.name,
      email: user.artist.email
    }
    await api.post("/OPUsers", data).then((result) => {
      if (result.data.id) {
        console.log(result.data.id);
      }
    });
    if (PO_link) {
      window.open(PO_link, '_blank', 'noopener,noreferrer');
      setLoading(false);
      handleGetOrders(`/getAllOrders?shop=${shop}&tag=ACCEPTED`);
      return;
    } else {
      setLoading(true);
      await api.get(`/htmlOPPdf?id=${id}`).then(async (result) => {
        if (result.data.PO_link) {
          console.log(result.data.PO_link);
        }
        setLoading(false);
      }).catch((error) => setLoading(false));
      handleGetOrders(`/getAllOrders?shop=${shop}&tag=ACCEPTED`,id);
    }
  }

  function formatDate(dateString) {
    const date = new Date(dateString);

// Get the components of the date
const day = date.toDateString().split(' ')[0];
const month = date.toDateString().split(' ')[1];
const dayOfMonth = date.getDate();
const year = date.getFullYear();

// Format the date as "Thu May 16 2024"
    const formattedDate = `${month} ${dayOfMonth} ${year}`;
    return formattedDate;
  }

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const handleTagValueChange = useCallback(
    (value) => setTagValue(value),
    [],
  );

  // const handleSort = useCallback(
  //   (index, direction) =>
  //     setSortedRows(sortCurrency(rows, index, direction)),
  //   [rows],
  // );

  

  const [tableHeader, setTableHeader] = useState([
    { title: 'Order'},
    { title: 'Order Date'},
    { title: 'Due Date' },    
    { title: 'Customer'},
    { title: 'Order Name'},
    { title: 'Order Status'},
    { title: 'Task Status'},    
    { title: 'Priority' },
    { title: 'Paid Status'},
    { title: 'History' },
    { title: 'Age'},
    { title: 'Print' },
    { title: 'PO Date' },
  ]);
  


  const Columns = [
    { title: 'Order', name: "orderId", icon:OrderIcon},
    { title: 'Order Date', name: "orderDate", icon:CalendarIcon},
    // { title: 'Due Date', name: "dueDate", icon: { XIcon } },
    { title: 'Customer', name: "customerName", icon:PersonIcon},
    { title: 'Order Name', name: "orderName", icon:OrderDraftIcon},
    { title: 'Order Status', name: "orderStatus", icon:OrdersStatusIcon},
    { title: 'Task Status', name: "taskStatus", icon:StatusIcon},
    { title: 'Priority', name: "Priority", icon: CircleChevronUpIcon },
    { title: 'Paid Status', name: "paidStatus", icon:PaymentIcon},
    { title: 'Age', name: "age", icon: ClockIcon },
    { title: 'Print', name: "print", icon: LabelPrinterIcon},
    { title: 'PO Date', name: "poDate", icon: CalendarTimeIcon },
    // { title: 'History',name: "history", icon: { XIcon } },
    // { title: 'PO Print',name: "editCurrentStatus", icon: { XIcon } },
  ]

  const [selectedArtist, setSelectedArtist] = useState(0);
  const [orderSort, setOrderSort] = useState('');
  const [tagsCount, setTagsCount] = useState({
    all: 0,
    awaitingProof: 0,
    sent: 0,
    accepted: 0,
    rejected: 0,
    proofAdded: 0,
    hotList: 0,
    awaitingDesign: 0,
    pendingApproval: 0,
    rush: 0,
    remake: 0
  })

  useEffect(() => { 
    handleGetSettings();
    handleTabsApi();
    handleGetOrders();
  }, []);

  useEffect(() => { 
    handleGetOrders();
  }, [orderSort]);

  useEffect(() => { 
    setTableHeader(Columns.filter((column) => {
      if (selected != 7) {
        if (column.name === "print" || column.name === "poDate") {
          return false
        } else return activeColumns[column.name]
      } else
        activeColumns[column.name]
    }
    ).map((column) => { return { title: column.name === "editCurrentStatus" ? "" : column.title } }))
  }, [activeColumns,selected]);

  useEffect(() => {
    if (selected === 7) {
      setTableHeader(Columns.filter((column) => activeColumns[column.name]).map((column) => { return { title: column.name === "editCurrentStatus" ? "" : column.title } }))
    } else {
      setTableHeader(Columns.filter((column) => {
        if (selected != 7) {
          if (column.name === "print" || column.name === "poDate") {
            return false
          } else return activeColumns[column.name]
        } else
          activeColumns[column.name]
      }).map((column) => { return { title: column.name==="editCurrentStatus" ? "": column.title } }))
    }
    // setTableHeader(Columns.filter((column) => {
    //   if (selected != 6) {
    //     if (column.name === "po" || column.name === "poDate") {
    //       return false
    //     } else return activeColumns[column.name]
    //   } else
    //     activeColumns[column.name]
    // }
    // ).map((column) => { return { title: column.name === "editCurrentStatus" ? "" : column.title } }))
  }, [selected]);

  

  const handleTabsApi = () => {
    api.get(`/getOrderCountByTag?shop=${shop}`).then((data) => data.data).then((data) => {
      setTagsCount({
        ...tagsCount,
        hotList: data.HOT_LIST ? data.HOT_LIST : 0,
        awaitingDesign: data.AWAITING_DESIGN ? data.AWAITING_DESIGN : 0,
        awaitingProof: data.AWAITING_PROOF?data.AWAITING_PROOF:0,
        sent: data.SENT?data.SENT:0,
        accepted: data.ACCEPTED?data.ACCEPTED:0,
        rejected: data.REJECTED?data.REJECTED:0,
        proofAdded: data.PROOF_ADDED ? data.PROOF_ADDED : 0,
        pendingApproval: data.PENDING_APPROVAL? data.PENDING_APPROVAL :0,
        rush: data.RUSH || 0,
        remake: data.REMAKE || 0
      });
    });
  }
  const handleGetOrders = (url = `/getAllOrders?shop=${shop}`,id=null) => {
    setLoading(true);
    api.get(`${url}&sort=${orderSort}`).then((data) => {
      setOrderList(data.data);
      setLoading(false);
      if (id) {
        // Loop through data.data array to find the matching id
        const item = data.data.find(item => item.id === id);
        console.log(item, item.PO_link);
        // If the id is found, open the PO_link in a new tab
        if (item && item.PO_link) {
            window.open(item.PO_link, '_blank', 'noopener,noreferrer');
        } else {
            console.log('ID not found or PO_link is missing.');
        }
    }
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex)
      switch (selectedTabIndex) {
        case 10:
          handleGetOrders();
          break;
          case 0:
            handleGetOrders(`/getAllOrders?shop=${shop}&tag=RUSH`);
          break;
        case 1:
          handleGetOrders(`/getAllOrders?shop=${shop}&tag=HOT_LIST`);
          break;
          case 2:
            handleGetOrders(`/getAllOrders?shop=${shop}&tag=PROOF_ADDED`);
          break;
          case 3:
            handleGetOrders(`/getAllOrders?shop=${shop}&tag=AWAITING_PROOF`);
          break;
          
          case 4:
            handleGetOrders(`/getAllOrders?shop=${shop}&tag=SENT`);
          break;
          case 5:
            handleGetOrders(`/getAllOrders?shop=${shop}&tag=REJECTED`);
          break;
          
          case 6:
            handleGetOrders(`/getAllOrders?shop=${shop}&tag=AWAITING_DESIGN`);
          break;
          case 7:
            handleGetOrders(`/getAllOrders?shop=${shop}&tag=ACCEPTED`);
          break;
          case 8:
          handleGetOrders(`/getAllOrders?shop=${shop}&tag=REMAKE`);
          break;
        default:
          handleGetOrders();
          break;
      }
    },
    [],
  );
  

  const tabs = [
    // {
    //   id: 'all',
    //   content: (
    //     <span style={{ width: "fit-content !important", minWidth:"fit-content !important" }}>
    //       All
    //     </span>
    //   ),
    //   accessibilityLabel: 'All customers',
    //   panelID: 'all-customers-fitted-content-3',
    // },
    {
      id: 'rush',
      content: (
        <span>
          Rush {tagsCount.rush ? <Badge tone="info">{tagsCount.rush}</Badge> : <span></span>}
        </span>
      ),
      panelID: 'accepts-marketing-fitted-content-3',
    },
    {
      id: 'hot-list',
      content: (
        <span>
          Hot List {tagsCount.hotList ? <Badge tone="info">{tagsCount.hotList}</Badge>:<span></span>}
        </span>
      ),
      panelID: 'accepts-marketing-fitted-content-3',
    },
    {
      id: 'added',
      content: (
        <span>
          Proof Added {tagsCount.proofAdded ? <Badge tone="info">{tagsCount.proofAdded}</Badge>:<span></span>}
        </span>
      ),
      panelID: 'accepts-marketing-fitted-content-3',
    },
    {
      id: 'awaiting',
      content: (
        <span>
          Awaiting Proof {tagsCount.awaitingProof ? <Badge tone="info">{tagsCount.awaitingProof}</Badge>:<span></span>}
        </span>
      ),
      panelID: 'accepts-marketing-fitted-content-3',
    },
    
    {
      id: 'sent',
      content: (
        <span>
          Proof Sent {tagsCount.sent ? <Badge tone="info">{tagsCount.sent}</Badge>:<span></span>}
        </span>
      ),
      panelID: 'accepts-marketing-fitted-content-3',
    },
    {
      id: 'rejected',
      content: (
        <span>
          Proof Rejected {tagsCount.rejected ? <Badge tone="info">{tagsCount.rejected}</Badge>:<span></span>}
        </span>
      ),
      panelID: 'accepts-marketing-fitted-content-3',
    },
    
    {
      id: 'awaitingDesign',
      content: (
        <span>
          Drawing Needed {tagsCount.awaitingDesign ? <Badge tone="info">{tagsCount.awaitingDesign}</Badge>:<span></span>}
        </span>
      ),
      panelID: 'accepts-marketing-fitted-content-3',
    },
    {
      id: 'accepted',
      content: (
        <span>
          Proof Accepted {tagsCount.accepted ? <Badge tone="info">{tagsCount.accepted}</Badge>:<span></span>}
        </span>
      ),
      panelID: 'accepts-marketing-fitted-content-3',
    },
    {
      id: 'remake',
      content: (
        <span>
          Remake {tagsCount.remake ? <Badge tone="info">{tagsCount.remake}</Badge>:<span></span>}
        </span>
      ),
      panelID: 'accepts-marketing-fitted-content-3',
    },
    // {
    //   id: 'pendingApproval',
    //   content: (
    //     <span>
    //       Pending Approval {tagsCount.pendingApproval ? <Badge tone="info">{tagsCount.pendingApproval}</Badge>:<span></span>}
    //     </span>
    //   ),
    //   panelID: 'accepts-marketing-fitted-content-3',
    // },
  ];

  const resourceName = {
    singular: 'order',
    plural: 'orders',
  };

// function formatDate(dateString) {
//     const date = new Date(dateString);
//     const day = date.getDate();
//     const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
//     return `${day} ${month}`;
// }
  
const handleUpdateOrder = (data) => {
  api.put("/updateOrder",data).then((result) => {
    console.log(result.data);
  });
}
  
const handleUpdateOrderTags = (data) => {
  api.put("/updateOrderTags",data).then((result) => {
    console.log(result.data);
  });
}
  
function updateOrderTags(orderTags, oldValue, newValue) {
  // Split the tags string into an array
  let tagsArray = orderTags.split(', ');

  // Filter out the old value
  tagsArray = tagsArray.filter(tag => tag !== oldValue);

  // Add the new value
  tagsArray.push(newValue);

  // Join the array back into a string
  return tagsArray.join(', ');
}
  
const handleUpdateOrderHistory = async (data) => {
  await api.post("/updateOrderHistory",data).then((result) => {
    console.log(result.data);
    const updatedOrderList = orderList.map(order => {
      if (order.id === id) {
        return { ...order, history: result.data.history };
      } else {
        return order;
      }
    });
    setOrderList(updatedOrderList);
  });
}
  
const handleDeleteOrderHistory = (data) => {
  api.post("/deleteFromOrderHistory",data).then((result) => {
    console.log(result.data);
    const updatedOrderList = orderList.map(order => {
      if (order.id === id) {
        return { ...order, history: result.data.history };
      } else {
        return order;
      }
    });
    setOrderList(updatedOrderList);
  });
}
  
  
  const [visible, setVisible] = useState(null);
  const [dueDateVisible, setDueDateVisible] = useState(null);
  const [query, setQuery] = useState("");
const [selectedDate, setSelectedDate] = useState(new Date());
const [{ month, year }, setDate] = useState({
  month: selectedDate.getMonth(),
  year: selectedDate.getFullYear(),
});
const [selectedDueDate, setSelectedDueDate] = useState(new Date());
const [{ dueMonth, dueYear }, setDueDate] = useState({
  dueMonth: selectedDueDate.getMonth(),
  dueYear: selectedDueDate.getFullYear(),
});
const formattedValue = selectedDate.toISOString().slice(0, 10);
  const datePickerRef = useRef(null);
  const dueDatePickerRef = useRef(null);
function handleInputValueChange() {
  console.log("handleInputValueChange");
}
function handleOnClose({ relatedTarget }) {
  setVisible(null);
}
function handleDueDateOnClose({ relatedTarget }) {
  setDueDateVisible(null);
}
function handleMonthChange(month, year) {
  setDate({ month, year });
}
function handleDueMonthChange(dueMonth, dueYear) {
  setDueDate({ dueMonth, dueYear });
}
function handleDateSelection({ end: newSelectedDate }) {
  setSelectedDate(newSelectedDate);
  setVisible(null);
}
useEffect(() => {
  if (selectedDate) {
    setDate({
      month: selectedDate.getMonth(),
      year: selectedDate.getFullYear(),
    });
  }
}, [selectedDate]);
  
useEffect(() => {
  if (selectedDueDate) {
    setDueDate({
      dueMonth: selectedDueDate.getMonth(),
      dueYear: selectedDueDate.getFullYear(),
    });
  }
}, [selectedDueDate]);
  
const taskList = [
  {
    title: "Proof Needed",
    color: "success",
  },
  {
    title: "Drawing Needed",
    color: "critical",
  },
  {
    title: "Proof Added",
    color: "info",
  },
  {
    title: "Design Approval Needed",
    color: "warning",
  },
  {
    title: "Event List Needed",
    color: "warning",
  },
  {
    title: "Proof Revision Needed",
    color: "warning",
  },
  {
    title: "Pending Approval",
    color: "attention",
  },
  {
    title: "In production",
    color: "warning",
  },
  {
    title: "Remake",
    color: "warning",
  },
];
const priorityList = [
  {
    title: "Rush",
    color: "critical",
  },
  {
    title: "Normal",
    color: "info",
  },
];

const statusList = [
  {
    title: "Awaiting Proof",
    value: "AWAITING_PROOF",
    color: "attention",
  },
  {
    title: "Proof Sent",
    value: "SENT",
    color: "info",
  },
  {
    title: "Proof Accepted",
    value: "ACCEPTED",
    color: "success",
  },
  {
    title: "Proof Rejected",
    value: "REJECTED",
    color: "critical",
  },
  {
    title: "Fulfilled",
    value: "FULFILLED",
    color: "critical",
  },
];
  
  function getBadge(history) {
    if (!history || history.length === 0) {
      return (
        <div className="badge default">
        <div className="icon"></div>
          Not set
        </div>
      );
    }
  
    const status = history[history.length - 1].status;
  
    const badges = {
      "Awaiting Shipment": (
        <div className="badge awaiting-shipment">
          <div className="icon"></div>
          Awaiting Shipment
        </div>
      ),
      "Awaiting Proof": (
        <div className="badge awaiting-proof">
          <div className="icon"></div>
          Awaiting Proof
        </div>
      ),
      "Proof Sent": (
        <div className="badge proof-sent">
          <div className="icon"></div>
          Proof Sent
        </div>
      ),
      "Proof Rejected": (
        <div className="badge proof-rejected">
          <div className="icon"></div>
          Proof Rejected
        </div>
      ),
      "Proof Accepted": (
        <div className="badge proof-accepted">
          <div className="icon"></div>
          Proof Accepted
        </div>
      ),
      "Backorder": (
        <div className="badge backorder">
          <div className="icon"></div>
          Backorder
        </div>
      ),
      "default": (
        <div className="badge default">
          <div className="icon"></div>
          Default Badge
        </div>
      ),
    };
  
    return badges[status] || badges["default"];
  }
  
    
  

  const rowMarkup = orderList?.map(
    (
      {
        _id,
        shop,
        id,
        created_at,
        customer,
        financial_status,
        fulfillment_status,
        name,
        due_date,
        current_status,
        task_status,
        priority,
        order_name,
        shipping_date,
        tags: t,
        history,
        attention,
        PO_link,
        P0_print,
        PO_date,
        PO_users
    },
      index,
    ) => (
      
      <IndexTable.Row id={id} key={id} position={index}>
        {activeColumns.orderId && <IndexTable.Cell className="Polaris-IndexTable__TableCell tc-bg"><span onClick={() => navigate(`/app/order?id=${id}&shop=${shop}`)} className="pointer">{name}</span></IndexTable.Cell>}
        {activeColumns.orderDate && <IndexTable.Cell><span onClick={() => navigate(`/app/order?id=${id}&shop=${shop}`)} className="pointer">{formatDate(created_at)}</span></IndexTable.Cell>}
        {false && <IndexTable.Cell>
          <BlockStack inlineAlign="center" gap="400">
      <div style={{  minWidth: "250px", maxWidth:"100%"}}>
              <Popover
                ariaHaspopup="dialog"
                fluidContent={true}
          active={dueDateVisible === id}
          autofocusTarget="none"
          fullWidth
          preferInputActivator={false}
                preferredAlignment="center" // Optional: Adjusts alignment
                preferredPosition="mostSpace" // Optional: Adjusts position
          preventCloseOnChildOverlayClick
          onClose={handleDueDateOnClose}
          activator={
            <TextField
              role="combobox"
              prefix={<Icon source={CalendarIcon} />}
              value={due_date?formatDate(due_date):null}
              onFocus={() => setDueDateVisible(id)}
              onChange={handleInputValueChange}
              autoComplete="off"
            />
          }
        >
          <Card ref={dueDatePickerRef}  >
            <DatePicker
              month={dueMonth}
              year={dueYear}
              selected={due_date?new Date(due_date):null}
              onMonthChange={handleDueMonthChange}                    
              onChange={({ end: newSelectedDate }) => {
                const updatedOrderList = orderList.map(order => {
                  if (order.id === id) {
                    return { ...order, due_date: newSelectedDate.toISOString()};
                  } else {
                    return order;
                  }
                });
                setSelectedDueDate(newSelectedDate);
                setDueDateVisible(null);
                setOrderList(updatedOrderList);
                handleUpdateOrder({
                  "shop":shop,
                  "id":id,
                  "due_date": newSelectedDate.toISOString()
               })
              }}      
            />
                </Card>  
        </Popover>
      </div>
    </BlockStack>
        </IndexTable.Cell>}
        {activeColumns.customerName && <IndexTable.Cell><span onClick={() => navigate(`/app/order?id=${id}&shop=${shop}`)} className="pointer">{customer.first_name+"  "+customer.last_name}</span></IndexTable.Cell>}
        {activeColumns.orderName && <IndexTable.Cell><input
          id={`input-${id}`}
          type="text"
          defaultValue={order_name}
          onChange={(e) => {
            const newValue = e.target.value;
            const updatedOrderList = orderList.map(order => {
              if (order.id === id) {
                return { ...order, order_name: newValue };
              } else {
                return order;
              }
            });
            setOrderList(updatedOrderList);
          }}
           onBlur={() => {
            document.getElementById(`input-${id}`).style.display = 'none'
            document.getElementById(`label-${id}`).style.display = 'inline'
             
             handleUpdateOrder({
                "shop":shop,
                "id":id,
                "order_name": order_name
             }) 
            //  if (order) {
            //    const updatedTags = updateOrderTags(order.tags, order_name, value);
            //    handleUpdateOrderTags({
            //      "shop": shop,
            //      "id": id,
            //      "tags": updatedTags
            //    })
            //  }
        }}
          style={{display:"none"}}
          autoFocus
        /><div style={{width:"100%", height:'20px'}} id={`label-${id}`} onClick={() => {
            document.getElementById(`label-${id}`).style.display = 'none'
            document.getElementById(`input-${id}`).style.display = 'inline'
        }
        }>{order_name}</div></IndexTable.Cell>}
        {/* {activeColumns.orderStatus && <IndexTable.Cell><span onClick={() => navigate(`/app/order?id=${id}&shop=${shop}`)} className="pointer"><Badge tone={tags[current_status]?.tone}>{tags[current_status].title}</Badge></span></IndexTable.Cell>} */}
        {activeColumns.orderStatus && <IndexTable.Cell><span className="pointer">
          <Popover
          active={activePopoverCurrentStatus === index}
          activator={
            <span onClick={() => setActivePopoverCurrentStatus(index)}>{ getBadgeByStatus(tags[current_status].title === "Drawing Needed" ? "Awaiting Proof" : tags[current_status].title)}</span>
          }
          onClose={() => setActivePopoverCurrentStatus(null)}
        >
          <ActionList
            items={statusList.map((badge) => ({
              content: (
                getBadgeByStatus(badge.title,tags[current_status].title===badge.title)
              ),
              onAction: () => {
                const updatedOrderList = orderList.map(order => {
                  if (order.id === id) {
                    return { ...order, current_status: badge.value };
                  } else {
                    return order;
                  }
                });
                setOrderList(updatedOrderList);
                setActivePopoverCurrentStatus(null);
                handleUpdateOrder({
                  "shop": shop,
                  "id": id,
                  "current_status": badge.value
                })
              },
            }))}
          />
        </Popover></span></IndexTable.Cell>}
        {activeColumns.taskStatus && <IndexTable.Cell><span className="pointer"><Popover
          active={activePopover === index}
          activator={
            <span onClick={() => setActivePopover(index)}>
              { getBadgeByStatus(task_status)}
              </span>
          }
          onClose={() => setActivePopover(null)}
        >
          <ActionList
            items={taskList.map((badge) => ({
              content: (
                 getBadgeByStatus(badge.title,task_status===badge.title)
              ),
              onAction: () => {
                const updatedOrderList = orderList.map(order => {
                  if (order.id === id) {
                    return { ...order, task_status: badge.title };
                  } else {
                    return order;
                  }
                });
                setOrderList(updatedOrderList);
                setActivePopover(null);
                handleUpdateOrder({
                  "shop": shop,
                  "id": id,
                  "task_status": badge.title
                })
              },
            }))}
          />
        </Popover></span></IndexTable.Cell>}
        
        {activeColumns.Priority && <IndexTable.Cell><span className="pointer">
          <Popover
          active={activePopoverPriority === index}
          activator={
            <span onClick={() => setActivePopoverPriority(index)}>{getBadgeByStatus(priority ? priority : "Normal")}</span>
          }
          onClose={() => setActivePopoverPriority(null)}
        >
          <ActionList
            items={priorityList.map((badge) => ({
              content: (
                getBadgeByStatus(badge.title)
              ),
              onAction: () => {
                const updatedOrderList = orderList.map(order => {
                  if (order.id === id) {
                    return { ...order, priority: badge.title };
                  } else {
                    return order;
                  }
                });
                setOrderList(updatedOrderList);
                setActivePopoverPriority(null);
                handleUpdateOrder({
                  "shop": shop,
                  "id": id,
                  "priority": badge.title
                })
              },
            }))}
          />
          </Popover></span></IndexTable.Cell>}
          {activeColumns.paidStatus  && <IndexTable.Cell><span onClick={() => navigate(`/app/order?id=${id}&shop=${shop}`)} className="pointer">{getBadgeByStatus(financial_status)}</span></IndexTable.Cell>}
        {/* {activeColumns.poDate && <IndexTable.Cell>
          <BlockStack inlineAlign="center" gap="400">
          <div style={{  minWidth: "250px", maxWidth:"100%"}}>
              <Popover
          preferredPosition="mostSpace"
          active={visible===id}
          autofocusTarget="none"
          preferredAlignment="left"
          fullWidth
          preferInputActivator={false}
          preventCloseOnChildOverlayClick
          onClose={handleOnClose}
          activator={
            <TextField
              role="combobox"
              prefix={<Icon source={CalendarIcon} />}
              value={shipping_date?formatDate(shipping_date):null}
              onFocus={() => setVisible(id)}
              onChange={handleInputValueChange}
              autoComplete="off"
            />
          }
        >
          <Card ref={datePickerRef}>
            <DatePicker
              month={month}
              year={year}
              selected={shipping_date?new Date(shipping_date):null}
              onMonthChange={handleMonthChange}                    
              onChange={({ end: newSelectedDate }) => {
                const updatedOrderList = orderList.map(order => {
                  if (order.id === id) {
                    return { ...order, shipping_date: newSelectedDate.toISOString()};
                  } else {
                    return order;
                  }
                });
                setSelectedDate(newSelectedDate);
                setOrderList(updatedOrderList);
                handleUpdateOrder({
                  "shop":shop,
                  "id":id,
                  "shipping_date": newSelectedDate.toISOString()
               })
              }}      
            />
          </Card>
        </Popover>
      </div>
    </BlockStack>
        </IndexTable.Cell>} */}
        {/* {activeColumns.history && <IndexTable.Cell><span onClick={() => { toggleActiveHistory(); handleActiveRowToggle(index) }} className="pointer">{history ? <><span style={{ color: "#6366F1", textDecoration: "underline", marginRight: "5px" }}>History</span><Badge>{history.length}</Badge></> : <Text as="span" fontWeight="bold" tone="subdued">No history</Text>}</span></IndexTable.Cell>} */}
        {activeColumns.age  && <IndexTable.Cell><span className="pointer">{timeAgo(created_at)}</span></IndexTable.Cell>}
        {(activeColumns.print && selected === 7) && <IndexTable.Cell>
          {P0_print ? 
          <div onClick={async () => {
              await handlePrintPurshaseOrder(PO_link, id);
          }} className="btn reprint-btn">Reprint Order</div>
            :
              <div onClick={async () => {
              await handlePrintPurshaseOrder(PO_link, id);
            }} className="btn print-btn">Print Order</div>
        }
          {/* <span className="pointer" style={{ color: "#6366F1" }}><Icon source={PrintIcon} tone="inherit" /></span> */}
        </IndexTable.Cell>}
        {(activeColumns.poDate && selected === 6)  && <IndexTable.Cell><span onClick={() => navigate(`/app/order?id=${id}&shop=${shop}`)} className="pointer">{PO_users?.length ? formatDate(PO_users[PO_users.length-1].date): ""}</span></IndexTable.Cell>}
      </IndexTable.Row>
    ),
  );

  const downloadCSV = function (data) { 

    // Creating a Blob for having a csv file format 
    // and passing the data with type 
    const blob = new Blob([data], { type: 'text/csv' }); 
  
    // Creating an object for downloading url 
    const url = window.URL.createObjectURL(blob) 
  
    // Creating an anchor(a) tag of HTML 
    const a = document.createElement('a') 
  
    // Passing the blob downloading url 
    a.setAttribute('href', url) 
  
    // Setting the anchor tag attribute for downloading 
    // and passing the download file name 
    a.setAttribute('download', 'download.csv'); 
  
    // Performing a download with click 
    a.click() 
  } 

  const downloadXSL = function (data) {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Change the type to Excel
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'download.xlsx'); // Change the file extension to .xlsx for Excel
    a.click();
}
  
  function convertArrayOfObjectsToCSV(data) {
    const header = Object.keys(data[0]).join(',') + '\n';
    const csv = data.map(row => Object.values(row).map(val => typeof val === 'object' ? JSON.stringify(val) : val).join(',')).join('\n');
    return header + csv;
  } 
  
  const makeFile = async function (type) { 
  
    // JavaScript object 
    const data = orderList?.map(
      (
        {
          _id,
          shop,
          id,
          created_at,
          customer,
          financial_status,
          fulfillment_status,
          name,
          due_date,
          current_status,
          order_name,
          task_status,
          priority
        },
        index,
      ) => ({
    "Order": name,
    "Order Date":formatDate(created_at), 
    "Due Date": formatDate(due_date),
    "Customer": customer.first_name+"  "+customer.last_name,
    "Order Name": order_name,
    "Order Status": task_status,
    "Task Status": tags[current_status].title,
    "Paid Status": financial_status,
    "Priority": priority?priority:"Normal",
      }));
    
    console.log(data);
    
    const csvdata = convertArrayOfObjectsToCSV(data); 
    if (type === "csv") {
      downloadCSV(csvdata);
    } else {
      downloadXSL(csvdata);
    }
  } 
  

  
  
 
  const handleButtonClick = (type) => {
    makeFile(type);
  };
  const handlePaginationButtonClick = () => {
    console.log("c");
  };

  const activator = (
    <Button icon={LayoutLogoBlockIcon} id="customize-btn"  onClick={togglePopoverActive} disclosure>
      Customize
    </Button>
  );

  const handleSearch = () => {
    // Perform API search call with the current query value
    if (query) {
      handleGetOrders(`/getSearchOrders?shop=${shop}&term=${query}`)
    } else {
      switch (selected) {
        case 10:
          handleGetOrders();
          break;
          case 0:
            handleGetOrders(`/getAllOrders?shop=${shop}&tag=RUSH`);
          break;
        case 1:
          handleGetOrders(`/getAllOrders?shop=${shop}&tag=HOT_LIST`);
          break;
          case 2:
            handleGetOrders(`/getAllOrders?shop=${shop}&tag=PROOF_ADDED`);
          break;
          case 3:
            handleGetOrders(`/getAllOrders?shop=${shop}&tag=AWAITING_PROOF`);
          break;
          
          case 4:
            handleGetOrders(`/getAllOrders?shop=${shop}&tag=SENT`);
          break;
          case 5:
            handleGetOrders(`/getAllOrders?shop=${shop}&tag=REJECTED`);
          break;
          
          case 6:
            handleGetOrders(`/getAllOrders?shop=${shop}&tag=AWAITING_DESIGN`);
          break;
          case 7:
            handleGetOrders(`/getAllOrders?shop=${shop}&tag=ACCEPTED`);
          break;
          case 8:
          handleGetOrders(`/getAllOrders?shop=${shop}&tag=REMAKE`);
          break;
        default:
          handleGetOrders();
          break;
      }
    }
    
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      console.log("enter is pressed")
      handleSearch();
    }
  };

  const handleBlur = () => {
    console.log("handle Blur")
    handleSearch();
  };

  function printDivContent() {
    var divContents = document.getElementById("contentToPrint").innerHTML;
    console.log(divContents);
    var a = window.open('', '', 'height=5000, width=5000');
    a.document.write('<html>');
    a.document.write('<body>');
    a.document.write(divContents);
    a.document.write('</body></html>');
    a.document.close();
    console.log(a);
    a.print();
}
  
  const componentRef = useRef();

  const inputStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #c4c4c4",
    borderRadius: "4px",
    padding: "0 8px",
    height: "40px",
    fontSize: "14px",
    width: "100%",
    backgroundColor: "#fff",
  };

  const iconStyle = {
    marginRight: "8px",
    display: "flex",
    alignItems: "center",
  };
  const handleQueryChange = useCallback((value) => {
    setQuery(value);
  }, []);
  
  
  return (
    <Page fullWidth>
      <CustomSpinner loading={loading} />
      {true && <div className="customize-container">
      <Popover
        active={popoverActive}
        activator={activator}
        onClose={togglePopoverActive}
        ariaHaspopup={false}
        sectioned
      >
      <div style={{ width: "300px" }}>
            <div style={{ display:"flex",justifyContent:"space-between", alignItems:"center", width:"100%"}}>
              
              <Text as="span" fontWeight="bold" tone="subdued">
              Customise
      </Text>
              <span style={{cursor:"pointer"}} onClick={togglePopoverActive}>
                <Icon source={XIcon}/>
              </span>
            </div>
            <div style={{margin:"20px 0px"}}>
            <Text as="span" fontWeight="bold" tone="subdued">
              Columns
              </Text>
              {Columns.map((column) => 
                <div style={{ border: "1px solid #CECECE", display: "flex", width: "100%", padding: "5px", borderRadius: "10px", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                  <span style={{ display: "flex", justifyContent: "start", gap: '5px', alignItems: "center", }}>
                    <Icon source={column.icon}  color="base" />
                    <Text as="span" fontWeight="bold" tone="subdued">
                      {column.title}
                    </Text>
                  </span>
                  <ReactSwitch
                    width={30}
                    height={14}
                    handleDiameter={12}
                    name={column.name}
                    checked={activeColumns[column.name]}
                    onChange={(event) => {
                      handleSave({ ...activeColumns, [column.name]: event });
                      setActiveColumns({ ...activeColumns, [column.name]: event });
                    }}
                    id="normal-switch"
                  />
                </div>
              )
              }
            </div>  
      <div>
              
      </div>
                 
      </div>
      </Popover>
      </div>}
      <Card id="MyCard" roundedAbove="xs" padding={5} >
      <LegacyCard>
      {/* <Tabs  tabs={tabs} selected={selected} onSelect={handleTabChange}>
      </Tabs> */}
          <div className="tab-bar">
      {tabs.map((tab,index) => (
        <div
          key={tab.name}
          className={`tab-item ${selected === index ? "active" : ""}`}
          onClick={() => handleTabChange(index)}
        >
          {tab.content}
          <span>{tab.name}</span>
          
        </div>
      ))}
    </div>
    </LegacyCard>
      {/* <LegacyCard>
      <LegacyTabs
        tabs={tabs}
        selected={selected}
        onSelect={handleTabChange}
        fitted
      >
          </LegacyTabs>
          </LegacyCard> */}
        
        <div style={{ padding: "20px" }}>
          <div className="order-filter">
          <div style={{flex:1}} onKeyDown={handleKeyPress}>
  <TextField
    value={query}
                onChange={handleQueryChange}
    fullWidth
                placeholder="Filter Orders"
                onBlur={() => handleBlur()}
                prefix={<Icon source={SearchIcon} color="base" />}
                
      // Ensure handleBlur doesn't interfere with focxwus
  />
</div>
            {/* <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
      <div style={inputStyle}>
        <span style={iconStyle}>
          <Icon source={SearchIcon} color="base" />
        </span>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Filter Orders"
          style={{
            border: "none",
            outline: "none",
            flex: 1,
            fontSize: "14px",
          }}
        />
      </div>
    </div> */}
            
            <div style={{width: "fit-content"}}>
            <Select
            id="sort-order-id"
            placeholder="Sort Orders"
            options={['Order Date New','Due Date', 'Order Date', 'Age Old to New']}
            value={orderSort}
              onChange={setOrderSort}
            />
            </div>
          </div>
          <div ref={componentRef}>
          <LegacyCard >
              {tableHeader.length && <IndexTable
                
              resourceName={resourceName}
              itemCount={orderList.length}
              headings={tableHeader}
              selectable={false}
          

            >
              {rowMarkup}
            </IndexTable>}
            </LegacyCard>
            </div>
          {/* <LegacyCard>
        <IndexTable
          resourceName={resourceName}
          itemCount={orderList.length}
              headings={tableHeader}
              selectable={false}
        >
          {rowMarkup}
        </IndexTable>
      </LegacyCard> */}
          <div className="bottom">
      
       
        <div className="table-footer-container">
        <div className="csvexcel">
          {user?.artist?.permissions?.admin &&<><Button id="csv-btn" onClick={() => handleButtonClick('csv')} primary>
            CSV
          </Button>
          <Button  id="csv-btn" onClick={() => handleButtonClick('excel')} primary>
            Excel
                  </Button>
                  <ReactToPrint
                trigger={() => <Button  id="csv-btn" primary>
                Print
              </Button>}
                content={() => componentRef.current}
            />
                  
                </>}
        </div>    
        {/* Pagination buttons */}
        <div className="pagenation-btns">
          <Button
            onClick={() => handlePaginationButtonClick('previous')}
            id="left-button"
            
          >
          <Icon
            source={ArrowLeftIcon}
            tone="base"
          />
          </Button>
              <Button
            id="right-button"
                  onClick={() => handlePaginationButtonClick('next')}
                  
             // Set to the appropriate value based on your logic
          >
          <Icon
            source={ArrowRightIcon}
            tone="base"
          />
          </Button>
              </div>
              </div>    
      
    </div>
        </div>
      </Card>
      {activeRow && <Modal
        size="fullscreen"
        open={activeHistory}
        onClose={ toggleActiveHistory}
        title={<div style={{ width: "100%", display: "flex", gap: "5px", justifyContent:"center", alignItems:"center" }}><span>{`History: ${orderList[activeRow].name}  /`} {orderList[activeRow]?.customer?.first_name+"  "+orderList[activeRow]?.customer?.last_name}</span>{getBadge(orderList[activeRow].history)}</div>}
    
        secondaryActions={{
          content: 'Cancel',
          onAction: () => { toggleActiveHistory() },
                  
        }}
      >
        <Modal.Section>
          <OrderHistoryTableComp order={orderList[activeRow]} handleDeleteOrderHistory={handleDeleteOrderHistory} />
        </Modal.Section>
      </Modal>}
    </Page>
    
  );
}

