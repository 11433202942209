import {
  Box,
  Link,
  Text,
  Icon,
  Modal,
  TextField,
} from "@shopify/polaris";
import { useState, useCallback, useEffect } from 'react';
import "./styles.customorder.css";
import {EmailIcon, ComposeIcon, PhoneIcon, SendIcon} from "@shopify/polaris-icons";
import "./styles.customorder.css";
import api from "../axiosClient";
import DueDateSectoionDetail from "./DueDateSectoionDetail";

export default function CustomerDetail({ orderList, customer, user, setLoading, setOrderList  }) {
  const [active, setActive] = useState(false);
  const [phoneActive, setPhoneActive] = useState(false);
  const [emailActive, setEmailActive] = useState(false);
  const [checked, setChecked] = useState(false);
  const [comment, setComment] = useState("");

  const togglePhoneActive = useCallback(() => setPhoneActive((phoneActive) => !phoneActive), []);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleEmailActive = useCallback(() => setEmailActive((emailActive) => !emailActive), []);

  const handleEmailSend = () => {
    const data = {
      "html":comment,
      "to": customer?.email,
    }
    handleUpdateOrderSendEmail(data);
  }
  
  const handleMsgSend = () => {
    const data = {
      "message":comment,
      "to": customer.phone||customer.default_address.phone,
    }
    handleUpdateOrderSendMsg(data);
  }


  useEffect(() => { 
    console.log("customer", customer);
  }, []);

  const handleUpdateOrderSendEmail = (data) => {
    setLoading(true);
    api.post("/orderDetailsSendEmail",data).then((result) => {
      console.log(result.data);
      setLoading(false);
      toggleEmailActive();
    }).catch((err) => setLoading(false));
  }
  const handleUpdateOrderSendMsg = (data) => {
    setLoading(true);
    api.post("/orderDetailsSendMessage",data).then((result) => {
      console.log(result.data);
      setLoading(false);
      toggleActive();
    }).catch((err) => setLoading(false));
  }

  const formatPhoneNumber = (phoneNumber) => {
    // Remove the country code (assuming +1)
    const cleaned = phoneNumber?.replace(/\D/g, '').substring(1);
  
    // Extract area code, first three digits, and last four digits
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  
    return null;
  };

  return (
          <Box id="custom-box">
      {user?.artist?.permissions?.admin && <div className="column-header">
        <Text as="h6" tone="subdued" fontWeight="semibold" >
          Customer
        </Text>
      </div>}
            <div className="right-column-body">
            {user?.artist?.permissions?.admin && <><Text as="h3" tone="subdued" fontWeight="semibold" >
            <span>{customer?.first_name  + " " + customer?.last_name}</span>
              </Text>
              <div style={{ display: "flex", gap: "15px", marginTop:"15px" }}>
                <span className="icon-wrapper" onClick={toggleEmailActive}><Icon source={EmailIcon} /></span>
                <span className="icon-wrapper" onClick={togglePhoneActive}><Icon source={PhoneIcon} /></span>
                <span className="icon-wrapper" onClick={toggleActive} ><Icon source={ComposeIcon} /></span>
        </div>
        {phoneActive &&
          <div style={{ marginTop: "10px" }}>
            <a style={{textDecoration:"none"}} href={`tel:${customer.phone||customer.default_address.phone}`}>              
            <Text as="h3" tone="subdued" fontWeight="bold" >
              {customer.phone||customer.default_address.phone? formatPhoneNumber(customer.phone||customer.default_address.phone):"No Phone Number"}
              </Text>
            </a>
            </div>}
          </>
        }
          <DueDateSectoionDetail task_status={orderList.task_status} id={orderList.id} shop={orderList.shop} due_date={orderList.due_date} shipping_date={orderList.shipping_date} setOrderList={setOrderList} order={orderList} order_name={orderList.order_name} />
      </div>  

      {(customer.phone || customer.default_address.phone) && (
        <Modal
          size="small"
          open={active}
          onClose={toggleActive}
          title="Message"
        >
          <Modal.Section>
            <div style={{ paddingBottom: "10px" }}>
              <Text as="h3" tone="subdued" fontWeight="semibold" >
                To:  {formatPhoneNumber(customer.phone || customer.default_address.phone)}
              </Text>
            </div>
            <TextField
              placeholder="Type message here..."
              value={comment}
              onChange={setComment}
              multiline={3}
              autoComplete="off"
              fullwidth
            
            />
          </Modal.Section>
          <Modal.Section>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: "15px" }}>
            <div onClick={() => handleMsgSend()} style={{ padding: "4px 10px", minWidth: "60px" }} className="btn print-btn">Send</div>
      </div>
      </Modal.Section>
        </Modal>
        )}
      
      <Modal
          size="small"
          open={emailActive}
          onClose={toggleEmailActive}
          title="Email"
        >
        <Modal.Section>
          <div style={{paddingBottom:"10px"}}>
        <Text as="h3" tone="subdued" fontWeight="semibold" >
            To:  {customer?.email}
            </Text>
            </div>
          <TextField
                placeholder="Type the email content here..."
                value={comment}
                onChange={setComment}
                multiline={3}
                autoComplete="off"
                fullwidth
            
              />
        </Modal.Section>
        <Modal.Section>
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: "15px" }}>
              <div onClick={() => handleEmailSend()} style={{ padding: "4px 10px", minWidth: "60px" }} className="btn print-btn">Send</div>
        </div>
        </Modal.Section>
        </Modal>
        </Box>         
  );
}
            
