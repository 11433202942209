import {
  Box,
  Icon,
  Modal,
  Text,
  TextField,
} from "@shopify/polaris";
import "./styles.customorder.css";
export default function OrderProofLink({ link }) {

  return (
    <Box id="notes-box">
          <div className="column-header">
          <Text as="h6" tone="subdued" fontWeight="semibold" >
          Approved Proof Link
        </Text>  
        
            </div> 
            <div className="right-column-body top-padding-remove">
        <Text as="span" fontWeight="regular" >
          {link}
        </Text>
      </div>  
      
          </Box>      
  );
}
            
